import React from "react"

import Hero from "layout/Hero"
import Container from "layout/Container"

import orderProcess from "../utils/orderProcess.json"

const HomeProcessMobile = () => {
  return (
    <Hero className="is-hidden-desktop is-hidden-tablet">
      <h1 className="title has-text-primary has-text-centered">
        A healthier you is just a few taps away
      </h1>
      <Container>
        <div className="content">
          <ol className="fancy-list">
            {orderProcess.map((process, index) => (
              <li key={index} className="subtitle">
                {process.process}
              </li>
            ))}
          </ol>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeProcessMobile
