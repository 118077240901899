/* eslint-disable */
import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Hero from "layout/Hero"
import Container from "layout/Container"

import orderProcess from "../utils/orderProcess.json"
import styles from "../utils/staticPages.module.scss"
import { animate } from "../../../services/animations"
import { gsap } from "gsap"

// const Step = ({ activeStep }) => {
//   const data = useStaticQuery(graphql`
//     {
//       one: file(relativePath: { eq: "home/index__step1.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       two: file(relativePath: { eq: "home/index__step2.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       three: file(relativePath: { eq: "home/index__step3.png" }) {
//         childImageSharp {
//           fluid(maxWidth: 300) {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//     }
//   `)

//   const one = data.one.childImageSharp.fluid
//   const two = data.two.childImageSharp.fluid
//   const three = data.three.childImageSharp.fluid

//   switch (activeStep) {
//     case 0:
//       return <Img fluid={one} />

//     case 1:
//       return <Img fluid={two} />

//     case 2:
//       return <Img fluid={three} />

//     default:
//       return <Img fluid={one} />
//   }
// }

const HomeProcessDesktop = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [stepOffset, setStepOffset] = useState(100)

  const data = useStaticQuery(graphql`
    {
      one: file(relativePath: { eq: "home/index__step1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      two: file(relativePath: { eq: "home/index__step2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      three: file(relativePath: { eq: "home/index__step3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleStepChange = (index) => {
    if (index > activeStep) {
      gsap.to(`.process-image-${activeStep}`, {
        display: "block",
        duration: 0.4,
        yPercent: -stepOffset * index - 10,
        opacity: 1,
      })
      gsap.to(`.process-image-${index}`, {
        display: "block",
        duration: 0.4,
        yPercent: -stepOffset * index,
        opacity: 1,
      })
    } else if (index < activeStep) {
      gsap.to(`.process-image-${index}`, {
        display: "block",
        duration: 0.4,
        yPercent: -stepOffset * index,
        opacity: 1,
      })
      gsap.to(`.process-image-${activeStep}`, {
        display: "block",
        duration: 0.4,
        yPercent: stepOffset + 100,
        opacity: 1,
      })
    }

    setActiveStep(index)
  }

  return (
    <Hero className="is-hidden-mobile">
      <Container isCentered>
        <div className="columns">
          <div className="column">
            <h1 className="title has-text-primary">
              A healthier you is just a few taps away
            </h1>
            {orderProcess.map((process, index) => (
              <div
                className={classNames("message", styles["message"])}
                onClick={() => {
                  handleStepChange(index)
                }}
              >
                <div
                  className={classNames(
                    "message-body",
                    styles["message__body"],
                    {
                      "has-border-left-primary": activeStep === index,
                    }
                  )}
                >
                  <p
                    className={classNames(
                      "is-uppercase",
                      styles["message__step"],
                      {
                        "has-text-weight-bold has-text-primary":
                          activeStep === index,
                      },
                      {
                        "has-text-normal has-text-light": activeStep !== index,
                      }
                    )}
                  >
                    Step {process.step}
                  </p>

                  <p
                    className={classNames(
                      {
                        "has-text-normal has-text-dark": activeStep === index,
                      },
                      {
                        "has-text-normal has-text-light": activeStep !== index,
                      }
                    )}
                  >
                    {process.process}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="column">
            <div
              className={classNames(
                "is-hidden-mobile",
                styles["processPreview"]
              )}
            >
              <div
                className={classNames(
                  "card-content",
                  styles["processView__imageContainer"]
                )}
              >
                <Img
                  className="process-image-0"
                  fluid={data.one.childImageSharp.fluid}
                />
                <Img
                  className={classNames(
                    "process-image-1",
                    styles["processPreview__processImages"]
                  )}
                  fluid={data.two.childImageSharp.fluid}
                />
                <Img
                  className={classNames(
                    "process-image-2",
                    styles["processPreview__processImages"]
                  )}
                  fluid={data.three.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
  )
}

export default HomeProcessDesktop
